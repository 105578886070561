'use client'

import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTheme, alpha } from '@mui/material/styles'
import { Container, Grid } from '@mui/material'

// Types
import type { ItemsProps } from '@/types/components/organisms/items'

// Components
const TitleAtom = dynamic(() => import('@/components/atoms/title'))
const ItemAtom = dynamic(() => import('@/components/atoms/item'))

const ItemsOrganism = (props: ItemsProps) => {
	// Props
	const { title, items } = props

	// Theme
	const theme = useTheme()

	return (
		<Grid
			sx={{
				position: 'relative',
				zIndex: 3,
				...(theme.palette.mode === 'light' && {
					backgroundImage: `linear-gradient(0deg, ${alpha('#f1f6fd', 0)} 15%, #f1f6fd 30%, #f1f6fd 65%, ${alpha(theme.palette.common.white, 0)} 100%)`
				})
			}}
		>
			<Container>
				<TitleAtom title={title} />

				<Grid display="flex" mt={{ xs: 1, md: 2 }} mb={{ xs: 12.5, md: 25 }}>
					<Grid container spacing={{ xs: 3, md: 6 }}>
						{items.map((item, index) => {
							return (
								<Grid key={index} item xs={12} sm={6}>
									<ItemAtom item={item} />
								</Grid>
							)
						})}
					</Grid>
				</Grid>
			</Container>
		</Grid>
	)
}

export default ItemsOrganism
